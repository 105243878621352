var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import DateRangePicker from "../../../elements/calendar/DateRangePicker.vue";
import dateField from "../../../elements/dateField.vue";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
let DataGridCell = class DataGridCell extends Vue {
    constructor() {
        super(...arguments);
        this.cellValue = this.formattedDateValue;
    }
    get translations() {
        return this.$store.state.translations;
    }
    get column() {
        return this.columns[this.name];
    }
    get formattedDateValue() {
        if (this.value.Value === null)
            return "";
        // Remove characters from C# version of EPOCH unix timestamp
        const unixTimestamp = parseInt(this.value.Value.replace(/\D/g, ""));
        let startDate = null;
        if (this.value.Value.includes("-")) {
            startDate = dayjs(this.value.Value).format("YYYY-MM-DD");
        }
        else {
            startDate = dayjs(unixTimestamp).format("YYYY-MM-DD");
        }
        const dateRange = {
            startDate,
            endDate: "",
        };
        return dateRange;
    }
    get startDate() {
        if (this.cellValue.startDate) {
            return dayjs(this.cellValue.startDate).format("DD-MM-YYYY");
        }
        return "";
    }
    cellValueChanged(newVal) {
        newVal.Value = dayjs(newVal.startDate).format("YYYY-MM-DD");
        this.valueChanged(newVal);
        this.value.Value = dayjs(newVal.startDate).format("YYYY-MM-DD");
    }
    valueChanged(value) {
        console.log({ dateValue: value });
        return value;
    }
    dateFormat(classes) {
        return classes;
    }
    togglePicker() {
        this.$refs.picker.openPicker();
    }
    get dataFormat() {
        {
            return {
                direction: "ltr",
                format: "dd-mm-yyyy",
                separator: " - ",
                changed: false,
                applyLabel: "Apply",
                cancelLabel: "Cancel",
                weekLabel: "W",
                customRangeLabel: "Custom Range",
                daysOfWeek: [
                    this.translations["calendar-sun"],
                    this.translations["calendar-mon"],
                    this.translations["calendar-tue"],
                    this.translations["calendar-wed"],
                    this.translations["calendar-thu"],
                    this.translations["calendar-fri"],
                    this.translations["calendar-sat"],
                ],
                monthNames: [
                    this.translations["calendar-jan"],
                    this.translations["calendar-feb"],
                    this.translations["calendar-mar"],
                    this.translations["calendar-apr"],
                    this.translations["calendar-may"],
                    this.translations["calendar-jun"],
                    this.translations["calendar-jul"],
                    this.translations["calendar-aug"],
                    this.translations["calendar-sep"],
                    this.translations["calendar-oct"],
                    this.translations["calendar-nov"],
                    this.translations["calendar-dec"],
                ],
                firstDay: 0,
            };
        }
    }
};
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "value", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "name", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "columns", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "row", void 0);
__decorate([
    Watch("cellValue")
], DataGridCell.prototype, "cellValueChanged", null);
__decorate([
    Emit()
], DataGridCell.prototype, "valueChanged", null);
DataGridCell = __decorate([
    Component({
        components: {
            DateRangePicker,
            dateField,
        },
    })
], DataGridCell);
export default DataGridCell;
